import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React from "react";

const MasterTemplateDispatcher = React.lazy(() => import('./components/master-template-dispatcher/MasterTemplateDispatcher-Home'));

const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <React.Suspense fallback={<></>}>
              <MasterTemplateDispatcher />
            </React.Suspense>
          } />
        <Route
          path="/nevoai"
          element={
            <React.Suspense fallback={<></>}>
              <MasterTemplateDispatcher />
            </React.Suspense>
          } />
      </Routes>
    </Router>
  );
};

export default App;
