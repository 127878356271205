import { createSlice } from "@reduxjs/toolkit";

const vaStateSlice = createSlice({
    name: "vaStateStore",
    initialState: {
        vaState: undefined,
        hasHeaderLoaded: false,
        activityId: undefined
    },
    reducers: {
        setVaState(state, action) {
            state.vaState = action.payload;
        },
        updateVaArgs(state, action) {
            if (state.vaState) {
                state.vaState.args = action.payload;
            }
        },
        setHeaderFlag(state, action) {
            state.hasHeaderLoaded = action.payload;
        },
        setActivityId(state, action) {
            state.activityId = action.payload;
        }
    }
});

export const vaStateSliceActions = vaStateSlice.actions;
export default vaStateSlice;