import { createSlice } from "@reduxjs/toolkit";

const carouselSlice = createSlice({
    name: "carouselStore",
    initialState: {
        currentCarouselId: undefined,
        carousels: {}
    },
    reducers: {
        addCarousel(state, action) {

            if (state.carousels[action.payload.carouselId]) {
                state.carousels[action.payload.carouselId] = {
                    attachments: {},
                    attachmentCount: 0
                }
            } else {
                state.carousels = {
                    ...state.carousels, [action.payload.carouselId]: {
                        attachments: {},
                        attachmentCount: 0
                    }
                }
            }
            state.currentCarouselId = action.payload.carouselId;
        },
        addCarouselAttachment(state, action) {
            if (state.carousels[action.payload.carouselId]) {
                state.carousels[action.payload.carouselId].attachments = {
                    ...state.carousels[action.payload.carouselId].attachments,
                    [action.payload.attachmentId]: action.payload
                }
                state.carousels[action.payload.carouselId].attachmentCount = Object.keys(state.carousels[action.payload.carouselId].attachments)?.length;
            }
        }

    }
});
export const carouselSliceActions = carouselSlice.actions;
export default carouselSlice;