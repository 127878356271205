
import { createSlice } from "@reduxjs/toolkit";
const ccStateSlice = createSlice({
    name: "ccStateStore",
    initialState: {
        ccState: undefined,
        activityId: undefined
    },
    reducers: {
        setCcState(state, action) {
            state.ccState = action.payload;
        },
        setActivityId(state, action) {
            state.activityId = action.payload;
        }
    }
});
export const ccStateSliceActions = ccStateSlice.actions;
export default ccStateSlice;